export default function normalize(s: string) {
  if (!s) return ''

  return s
    .toLowerCase()
    .normalize('NFD')
    .replaceAll(/\p{Diacritic}/gu, '')
    .replaceAll(' / ', '-')
    .replaceAll(' ', '-')
}

export function normalizeUrl(url: string) {
  return url
    .replaceAll("'", '%27')
    .replaceAll('(', '%28')
    .replaceAll(')', '%29')
}
